<template>
    <div class="conctact">
        <header-components activeClass="Contact"></header-components>

        <div class="section section-xl pb-5 mb-5 push-top">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center-sm">
                    <div class="col-md-5 px-lg-5 mb-5 mb-md-0">
                        <img data-src="https://assets.scola.id/newwebsite/images/cs-images.png" alt="User Testimonial"
                            class="w-100 w-sm lozad">
                    </div>
                    <div class="col-md-6 px-lg-5">
                        <h1 class="title-lg mb-3">Butuh Bantuan?</h1>
                        <p class="mb-">Tim Scola siap membantu anda untuk mengenal kami lebih baik, menjawab terkait
                            kebutuhan dan pertanyaan anda.
                        </p>
                        <div class="d-block mb-3">
                            <div class="text-sm font-500">Email</div>
                            <div>halo@scola.id</div>
                        </div>
                        <hr>
                        <div class="d-block mb-3">
                            <div class="text-sm font-500 mb-2">Pemasaran & Kemitraan</div>
                            <div class="d-flex align-items-center d-flex-mobile-center d-flex-contact">
                                <div class="link mr-2"><a
                                        href="https://wa.me/6281296400497?text=Halo+Scola!+Saya+membutuhkan+informasi+pemasaran+dan+kemitraan+di+Scola"
                                        target="_blank" class="btn btn-outline-primary btn-sm"
                                        rel="noopener noreferrer">
                                        <span class="fe fe-message-circle mr-2"></span>WhatsApp</a></div>
                                <div class="content text-sm">+62-81296400497 - <span class="fe fe-user"></span> Bella
                                </div>
                            </div>
                        </div>
                        <div class="d-block mb-3">
                            <div class="text-sm font-500 mb-2">Layanan &amp; Dukungan Teknis</div>
                            <div class="d-flex align-items-center d-flex-mobile-center d-flex-contact">
                                <div class="link mr-2"><a href="https://api.whatsapp.com/send?phone=6281318667468"
                                        target="_blank" rel="noopener noreferrer"
                                        class="btn btn-outline-primary btn-sm">
                                        <span class="fe fe-message-circle mr-2"></span>WhatsApp</a></div>
                                <div class="content text-sm">+62-81318667468 - <span class="fe fe-user"></span> Jojo
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Conact',
        components: {
            'header-components': Header,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Kontak',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function (hashbang) {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>
