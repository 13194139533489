<template>

    <div>
        <header-components activeClass="solusi-scola-orangtua"></header-components>

        <!-- Banner -->
        <div class="home-banner banner-solusi-ortu">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Solusi Scola untuk Para Orang Tua</h1>
                            <h2 class="mb-4 font-weight-normal lined lined-yellow">Kemudahan monitoring aktivitas dan pembelajaran anak anda. Kami memberikan kemudahan bagi orangtua dalam hal monitoring perkembangan belajar anak, aktivitas dan jadwal belajar anak.</h2>
                            <!-- <router-link to="#videoIntro" @click.native="scrollFix('#videoIntro')" class="btn btn-primary px-5">Mengapa Scola?</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-lg pb-0">
            <div class="d-block text-center">
                <h2 class="title-lg m-0 font-weight-bolder">Pantau Perkembangan Belajar Anak Anda</h2>
            </div>
        </div>

        <div class="section section-xl pb-2">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 order-md-last mb-md-5 mb-5 text-center">
                                <img src="images/1-ortu-laporan.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Pantau Rapor Anak</div>
                                <p>Kemudahan melihat hasil progres pembelajaran dan nilai hasil belajar anak secara langsung dimanapun dan kapanpun, rapor membantu orang tua untuk mengevaluasi kualitas belajar anak dengan cepat setiap saat tanpa menunggu rapor per semester.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-xl pb-2">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 text-center">
                                <img src="images/2-ortu-pembayaran.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Informasi Keuangan</div>
                                <p>Membantu orang tua mendapatkan informasi pembayaran uang sekolah dan riwayat pembayaran Sumbangan Pembinaan Pendidikan (SPP) anak hanya dengan 1 sentuhan dalam aplikasi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-xl pb-2">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 order-md-last mb-md-5 mb-5 text-center">
                                <img src="images/3-ortu-jadwal.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Akses Jadwal Belajar</div>
                                <p>Memudahkan orang tua dalam memantau semua kegiatan belajar dan mengajar yang diikuti anak yang terorganisir dalam jadwal belajar dan membantu orang tua dapat mengetahui modul/materi apa yang diajarkan oleh guru kepada anak di kelas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-xl">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 text-center">
                                <img src="images/4-ortu-pengumuman.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Pengumuman Resmi Sekolah</div>
                                <p>Mendapatkan informasi perihal agenda akademik di sekolah seperti Penilaian Akhir Semester dan melihat kegiatan terbaru yang dilaksanakan di sekolah baik berupa seminar, kegiatan keagamaan atau agenda sosial yang digelar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Video Section -->
        <div id="videoIntro" class="section section-xl bg-image bg-image-clear bg-primary card-style-bottom-left card-3d-bottom" style="background-image:url('images/banner-bg-1.jpg');">
            <div class="container">
                <div class="row align-items-center">
                <div class="col-md-7 pr-md-5 mb-md-0 mb-5">
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/78dfae2ebbd79f9afc546567dc047466ed4ea08d7762296f8a6d48d99d9610ec20210205071531.mp4" data-lity>
                    <div class="video-box shadow" style="background-image:url('images/banner-testimoni-scola-3.png');">
                        <span class="fe fe-play-circle m-0"></span>
                    </div>
                    </a>
                </div>
                <div class="col-md-5 text-center-sm">
                    <div class="font-weight-bolder text-xlg mb-2 mb-md-4 text-white">Semangat Belajar Anak Tinggi</div>
                    <p class="mb-4 lined lined-yellow text-white">"Saya sebagai orang tua merasa senang dan bangga karena anak saya menemukan solusi masalah belajarnya dan akhirnya semangat belajar anak saya semakin tinggi."
                        <br>
                        <br>
                        <span class="text-sm text-warning">Handita</span><br>
                        <span class="small" style="margin-top:-1px;display:block;">Orang Tua Zefanya, Kelas 10 D - SMP St. Aloysius Batununggal</span>
                    </p>
                </div>
                </div>
            </div>
        </div>

        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>

</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import lozad from 'lozad'

  export default {
    name: 'solusi-scola-orangtua',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'help-cta-components': HelpCta
    },
    metaInfo: {
      title: 'Solusi Scola untuk Orang Tua',
      titleTemplate: 'Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    data() {
        return {
            schoolTesti: {
                centerMode: true,
                centerPadding: "300px",
                infinite: true,
                slidesToShow: 1,
                responsive: [
                    {
                    breakpoint: 991,
                        settings: {
                            centerPadding: "100px"
                        }
                    },
                    {
                    breakpoint: 767,
                        settings: {
                            centerMode: false,
                            centerPadding: "0px",
                            dots: true,
                            autoplay: true,
                            autoplaySpeed:  3000
                        }
                    }
                ]
            },
        }
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>
