<template>
    <div class="best-features">
        <header-components activeClass="best-features"></header-components>
        <!-- Banner -->
        <div class="home-banner banner-virtual-class">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Virtual Class</h1>
                            <h2 class="mb-0 font-weight-normal lined lined-yellow">Virtual class yang tersedia di Scola
                                LMS mendukung proses pembelajaran jarak jauh menjadi lebih interaktif antara guru dan
                                siswa.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block bg-primary-gradient p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">
                        <router-link to="fitur-andalan" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="ujian-online" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Ujian Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="virtual-class" class="card m-0 text-white bg-primary">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Interaktif</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="manajemen-sistem" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Sistem Manajemen</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Quick Link -->
        <div class="d-block py-4 bg-lighter border-bottom quick-link">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 px-lg-5">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="d-flex link-list text-sm font-weight-bolder">
                                <router-link to="#featuresItem" @click.native="scrollFix('#featuresItem')" class="mr-5 text-primary">Layanan Fitur</router-link>
                                <router-link to="#keuntunganPengguna" @click.native="scrollFix('#keuntunganPengguna')" class="mr-5 text-primary">Keuntungan Pengguna</router-link>
                                <!-- <a href="#faq" class="text-primary">FAQ</a> -->
                                <!-- <a href="#faq" class="mr-5">FAQ</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Features -->
        <div id="featuresItem" class="section section-xl bg-white pt-5">
            <div class="container">
                <!-- <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Penguna Scola LMS </div>
                    </div>
                </div> -->
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section setion-lg">
                            <div class="d-block">
                                <div class="feature-img-3">
                                    <img data-src="https://assets.scola.id/images/feature-virtual-class.jpg" class="w-100 lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-virtual-class-addon-1-new.svg" class="img-animate lozad">
                                    <img data-src="https://assets.scola.id/images/feature-virtual-class-foreground.png" class="w-100 img-foreground lozad">
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/feedback.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Chatting</div>
                                    <p class="text-md mb-0">Fitur untuk komunikasi antar peserta didik dalam webinar
                                        atau conference dengan guru.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/webcam.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kamera Web</div>
                                    <p class="text-md mb-0">User yang tergabung dalam virtual class dapat menampilkan
                                        wajah melalui webcam jika perangkat tersedia.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/laptop.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Presentasi Layar</div>
                                    <p class="text-md mb-0">Peserta didik/guru dapat berbagi layar untuk menampilkan
                                        materi pada webinar atau conference.Guru menjadi lebih mudah dalam pengelolaan
                                        kuis & ujian hingga proses penilaian. Tersedia bank soal yang membantu guru
                                        untuk mendapatkan konten soal.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/teamwork.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelompok Kecil</div>
                                    <p class="text-md mb-0">Kelas dapat dibagi menjadi beberapa ruangan/kelompok.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/online-class.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Manajemen Virtual Class</div>
                                    <p class="text-md mb-0">Admin dapat mengatur jadwal webinar dan lainnya yang akan
                                        digunakan oleh guru dan siswa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="keuntunganPengguna" class="section section-lg bg-lighter">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Keuntungan Pengguna pada Virtual Class </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-3.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Sekolah</div>
                                <p class="mb-3">Sekolah dapat mengelola virtual class untuk diakses oleh guru dan siswa.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-4.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Guru</div>
                                <p class="mb-3">Guru melakukan virtual class (Pembelajaran jarak jauh) dengan mudak
                                    dengan siswa.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-1.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Siswa</div>
                                <p class="mb-3">Siswa dapat mengikuti virtual class(pembelajaran jarak jauh) yang telah
                                    dibuat oleh guru dimanapun dan kapanpun.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-2.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Orang Tua</div>
                                <p class="mb-3">Orang Tua dapat melihat jadwal Virtual Class yang akan diikuti oleh
                                    siswa.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FAQ -->

        <div class="container px-lg-5">
            <div class="d-block pb-3 pt-3 border-bottom">
                <div class="d-flex justify-content-between">
                    <a href="ujian-online" class="pagination-link">
                        <div class="text-sm d-block mb-2"><span class="fe fe-arrow-left mr-2"></span>Sebelumnya</div>
                        <div class="d-block font-weight-bolder text-lg">UJIAN ONLINE</div>
                    </a>
                    <a href="manajemen-sistem" class="pagination-link pagination-next">
                        <div class="text-sm d-block mb-2">Selanjutnya<span class="fe fe-arrow-right ml-2"></span></div>
                        <div class="d-block font-weight-bolder text-lg">SISTEM MANAJEMEN</div>
                    </a>
                </div>
            </div>
        </div>
        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Cta from '@/components/Cta.vue'
    import lozad from 'lozad'

    export default {
        name: 'best-features',
        components: {
            'header-components': Header,
            'footer-components': Footer,
            'help-cta-components': HelpCta,
            'cta-components': Cta
        },
        metaInfo: {
            title: 'Virtual Class',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>