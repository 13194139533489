<template>

    <div>
        <header-components activeClass="solusi-scola-sekolah"></header-components>

        <!-- Banner -->
        <div class="home-banner banner-solusi-sekolah">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Solusi Scola untuk Sekolah</h1>
                            <h2 class="mb-4 font-weight-normal lined lined-yellow">Dunia Pendidikan yang baru diciptakan Scola sebagai jawaban bagi Anda untuk menerapkan kegiatan belajar mengajar serta pengawasan secara digital yang efektif.</h2>
                            <router-link to="#videoIntro" @click.native="scrollFix('#videoIntro')" class="btn btn-primary px-5">Kenapa Scola?</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="section section-xl pb-5 shadow section-bg-type-1">
            <div class="container mb-4">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-8 text-center">
                        <img src="images/digital-learning-logo.svg" width="200">
                        <div class="title-lg font-weight-bolder mb-4 mt-4"> Make digital learning simple for everyone </div>
                        <p>Equitable access to remote learning has never been more important. Digital learning by creating one friendly place for resources and instruction—whether signin on at school, at home, or both.</p><br>
                        <router-link to="register" class="text-sm"><span class="fe fe-arrow-right mr-2"></span>Gratis masa coba selama 30 hari</router-link>
                    </div>
                </div>
            </div>
        </div> -->

         <!-- Video Section -->
        <div id="videoIntro" class="section section-xl bg-image bg-image-clear bg-primary card-style-bottom-left card-3d-bottom" style="background-image:url('images/banner-bg-1.jpg');">
            <div class="container">
                <div class="row align-items-center">
                <div class="col-md-7 pr-md-5 mb-md-0 mb-5">
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_b2ec9d687f272f73dd7dba3e98df2f09f2b64eec80cf4df36b1a6093cb684f0620210203070713.mp4" data-lity>
                    <div class="video-box shadow" style="background-image:url('images/banner-testimoni-scola-1.png');">
                        <span class="fe fe-play-circle m-0"></span>
                    </div>
                    </a>
                </div>
                <div class="col-md-5 text-center-sm">
                    <div class="font-weight-bolder text-xlg mb-2 mb-md-4 text-white"><b>Apa Kata Sekolah?</b></div>
                    <p class="mb-4 lined lined-yellow text-white">"Aplikasi ini menjadi harapan saya untuk pemebelajaran secara digital yang terstruktur. Sekolah sangat terbantu sekali untuk memciru para guru ke era teknologi masa depan."
                        <br>
                        <br>
                        <span class="text-warning">Felix, S.T., M.Kom.</span><br>
                        <span class="small" style="margin-top:-1px;display:block;">Wakil Kepala Sekolah SMAK 2 BPK</span>
                    </p>
                </div>
                </div>
            </div>
        </div>

        <div class="section section-lg pb-0">
            <div class="d-block text-center">
                <h2 class="title-lg m-0 font-weight-bolder">Dunia Pendidikan yang <span class="underline underline-2 underline-lg">Kami Ciptakan</span></h2>
            </div>
        </div>

        <div class="section section-xl pb-2">
            <div class="container">
                <div class="row justify-content-center">
                    
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 text-center">
                                <img src="https://assets.scola.id/images/class-scola-1.png" width="320">
                            </div>
                            <div class="col-md-6 pl-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Metode Pembelajaran yang Relevan Bagi Siswa</div>
                                <p>Belajar kini bukan lagi menjadi hal yang terbatas dalam ruang kelas. Siswa kini dapat mengakses kapanpun dan dimanapun sesuai dengan kebutuhan.</p>
                                <router-link to="fitur-andalan" class="text-sm">Pelajari mengenai <span class="underline">Kelas Online</span> <span class="fe fe-arrow-right mr-2"></span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg pb-2">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 order-md-last mb-md-5 mb-5 text-center">
                                <img src="https://assets.scola.id/images/class-scola-2.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Melibatkan Partisipasi Orang Tua dalam Mendukung Kegiatan Pembelajaran</div>
                                <p>Pengawasan yang terbaik adalah dari rumah, partisipasi orang tua dalam mendukung anak untuk mengikuti program kami akan menjadi dorongan terbaik untuk anak.</p>
                                <router-link to="virtual-class" class="text-sm">Pelajari mengenai <span class="underline underline-3">Solusi Scola untuk Orang Tua</span> <span class="fe fe-arrow-right mr-2"></span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 text-center">
                                <img src="https://assets.scola.id/images/class-scola-3.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Memberikan Ruang Kelas Digital untuk Guru</div>
                                <p>Kehadiran Kelas Digital menjadi jawaban bagi para guru untuk memberikan materi pendidikan serta wadah komunikasi untuk siswa.</p>
                                <router-link to="virtual-class" class="text-sm">Pelajari mengenai <span class="underline">Virtual Class Scola</span> <span class="fe fe-arrow-right mr-2"></span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 order-md-last text-center">
                                <img src="https://assets.scola.id/images/class-scola-4.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Dukungan dan Pengawasan yang Terintegrasi dengan Cepat dan Membangun</div>
                                <p>Dunia pendidikan baru yang Scola ciptakan adalah bentuk wujud dukungan Scola terhadap pendidikan yang lebih maju. Sistem yang terintegrasi, cepat dan membangun menjadikan Scola sebagai acuan untuk dunia pendidikan masa depan.</p>
                                <router-link to="register" class="text-sm">Daftar sekarang! <span class="underline underline-2">Gratis masa coba selama 30 hari</span> <span class="fe fe-arrow-right mr-2"></span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-lg">
            <div class="container-fluid container-fluid-mobile mb-4">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Cerita sekolah tentang Scola LMS </div>
                    </div>
                </div>
            </div>
            <div class="school-slider mb-5">
                <VueSlickCarousel v-bind="schoolTesti">

                    <div class="d-block p-3">
                        <div class="card bg-primary shadow-sm mb-0">
                            <div class="d-block">
                                <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/2174448b3cd1b8fe3c694028ac5462c093eb7613534bd45d350518867a016d3920210205071514.mp4" data-lity>
                                    <div class="video-box shadow">
                                        <img src="/images/banner-testimoni-scola-1.png" class="w-100 video-box-img">
                                        <span class="fe fe-play-circle m-0"></span>
                                    </div>
                                </a>
                            </div>
                            <div class="card-body text-sm text-white">
                                <span class="text-warning">Felix, S.T., M.Kom.</span><br>
                                <span class="small" style="margin-top:-1px;display:block;">Wakil Kepala Sekolah SMAK 2 BPK</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="card bg-primary shadow-sm mb-0">
                            <div class="d-block">
                                <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/d734070434e480252e4d8ebcd60f17852b4e7f4c5104b053d6a1b6ee8bb3c35820210205071158.mp4" data-lity>
                                    <div class="video-box shadow">
                                        <img src="/images/banner-testimoni-scola-2.png" class="w-100 video-box-img">
                                        <span class="fe fe-play-circle m-0"></span>
                                    </div>
                                </a>
                            </div>
                            <div class="card-body text-sm text-white">
                                <span class="text-warning">Dr. Hj. Anne Sukmawati, KD., M.Pd.</span><br>
                                <span class="small" style="margin-top:-1px;display:block;">Kepala Sekolah SMKN 9 Bandung</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="card bg-primary shadow-sm mb-0">
                            <div class="d-block">
                                <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_9f6fee405aa610bfb801f6874a213d547f1d6eb58e0b0aaecbcc59acfa1de54320210205074453.mp4" data-lity>
                                    <div class="video-box shadow">
                                        <img src="/images/banner-testimoni-scola-8.png" class="w-100 video-box-img">
                                        <span class="fe fe-play-circle m-0"></span>
                                    </div>
                                </a>
                            </div>
                            <div class="card-body text-sm text-white">
                                <span class="text-warning">Miss Nur Aini, S.Pd.</span><br>
                                <span class="small" style="margin-top:-1px;display:block;">Kepala Sekolah SD Palm Kids Purwokerto</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="card bg-primary shadow-sm mb-0">
                            <div class="d-block">
                                <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_7bbe4eb3bbfd077e9e51c8fe36674b6a40ecfaf29446ffad459a2c2c40e11dda20210208034731.mp4" data-lity>
                                    <div class="video-box shadow">
                                        <img src="/images/banner-testimoni-scola-10.png" class="w-100 video-box-img">
                                        <span class="fe fe-play-circle m-0"></span>
                                    </div>
                                </a>
                            </div>
                            <div class="card-body text-sm text-white">
                                <span class="text-warning">Yostian Ekaputra</span><br>
                                <span class="small" style="margin-top:-1px;display:block;">Yayasan IT BPK Penabur Bogor</span>
                            </div>
                        </div>
                    </div>
                    
                </VueSlickCarousel>
            </div>
        </div>
        
        <school-list-components></school-list-components>
        <help-cta-components></help-cta-components>
        <cta-components></cta-components>
        <footer-components></footer-components>
    </div>

</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import SchoolList from '@/components/SchoolList.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import Cta from '@/components/Cta.vue'
  import lozad from 'lozad'
  import VueSlickCarousel from 'vue-slick-carousel';
  

  export default {
    name: 'solusi-scola-sekolah',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'school-list-components': SchoolList,
      'help-cta-components': HelpCta,
      'cta-components': Cta,
      VueSlickCarousel
    },
    metaInfo: {
      title: 'Solusi Scola untuk Guru',
      titleTemplate: 'Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    data() {
        return {
            schoolTesti: {
                // centerMode: true,
                // centerPadding: "300px",
                infinite: true,
                slidesToShow: 3,
                dots: true,
                autoplay: true,
                autoplaySpeed:  3000,
                responsive: [
                    {
                    breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            },
        }
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>
