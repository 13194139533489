<template>
    <div class="">
        <div class="section section-lg pt-0 bg-lighter">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 px-lg-5">
                        <div class="d-block section section-lg pb-0 sticky-top">
                            <a href="/"><img src="images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo"
                                    width="120"></a>
                            <div class="d-block mt-4">
                                <h1 class="title-xl mb-4 font-weight-bolder">Coba aplikasi Scola sekarang — Gratis!*
                                </h1>
                                <h2 class="title-md font-weight-normal">Kami membantu sekolah, guru serta orang tua
                                    mewujudkan sekolah masa depan.</h2>
                                <div class="d-inlin-block mt-3 text-sm text-dark">*Gratis masa coba selama 30 hari</div>
                                <br>
                                <a href="/" class="text-sm">Kembali ke beranda</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 px-lg-5">
                        <div class="section section-lg pb-0">
                            <div class="card shadow-sm mb-0">
                                <div class="card-header border-bottom">
                                    <router-link class="text-sm font-weight-bolder mb-2" to="/about#ProsesBergabung">
                                        Lihat tahapan pendaftaran<span class="fe fe-arrow-right ml-2"></span>
                                    </router-link>
                                </div>
                                <div class="card-body">
                                    <h2 class="mb-4">Isi formulir pendaftaran sekolah</h2>
                                    <div class="form-group">

                                        <div class="d-block p-4 border rounded">
                                            <div class="text-xs d-block mb-3">Daftar sebagai...</div>
                                            <div class="d-flex align-items-center text-sm">
                                                <div class="custom-control custom-radio mr-4">
                                                    <input type="radio" id="userRadio1" v-model="form.role" name="role"
                                                        class="custom-control-input" checked value="kepsek">
                                                    <label class="custom-control-label" for="userRadio1">Kepala
                                                        Sekolah</label>
                                                </div>
                                                <div class="custom-control custom-radio mr-4">
                                                    <input type="radio" id="userRadio2" v-model="form.role" name="role"
                                                        class="custom-control-input" value="guru">
                                                    <label class="custom-control-label" for="userRadio2">Guru</label>
                                                </div>
                                                <div class="custom-control custom-radio mr-4">
                                                    <input type="radio" id="userRadio3" v-model="form.role" name="role"
                                                        class="custom-control-input" value="ortu">
                                                    <label class="custom-control-label" for="userRadio3">Orang
                                                        Tua</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form class="needs-validation" @submit.prevent="registerData" id="registerForm" method="post">
                                        <div class="form-group">
                                            <label class="text-sm">Nama lengkap</label>
                                            <div class="d-flex">
                                                <div class="input-group-prepend flex-shrink-0">
                                                    <select class="custom-select" name="title" v-model="form.title">
                                                        <option selected value="Bapak">Bapak</option>
                                                        <option value="Ibu">Ibu</option>
                                                    </select>
                                                </div>
                                                <div class="w-100">
                                                    <input type="text" class="form-control rounded-l-none" required
                                                        v-model="form.full_name">
                                                    <div class="invalid-feedback">
                                                        Mohon lengkapi dengan nama lengkap Anda.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-sm">Alamat email</label>
                                            <input type="email" class="form-control" required v-model="form.email">
                                            <div class="invalid-feedback">
                                                Mohon isi alamat email Anda dengan benar.
                                            </div>
                                            <small class="form-text text-muted mt-2">Kami tidak akan pernah membagikan
                                                email Anda dengan orang lain.</small>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-sm">Nomor telepon</label>
                                            <input type="number" class="form-control" required v-model="form.phone">
                                            <div class="invalid-feedback">
                                                Mohon lengkapi nomor telepon Anda.
                                            </div>
                                            <div class="d-flex mt-2 px-4 py-3 bg-light rounded align-items-center">
                                                <div class="mr-4"><img src="images/warning.svg" width="28">
                                                </div>
                                                <div class="text-xs">
                                                    <p class="mb-0">Pastikan nomor yang tertera dapat dihubungi melalui
                                                        aplikasi WhatsApp</p>
                                                </div>
                                            </div>
                                            <small class="form-text text-muted mt-2">Kami tidak akan pernah membagikan
                                                nomor telepon Anda dengan orang lain.</small>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-sm">Nama sekolah</label>
                                            <input type="text" class="form-control" required v-model="form.school_name">
                                            <div class="invalid-feedback">
                                                Mohon lengkapi nama sekolah Anda.
                                            </div>
                                        </div>
                                        
                                        <div class="form-group">
                                            <vue-recaptcha ref="visibleRecaptcha" size="visible" @verify="onCaptchaVerify" @expired="onCaptchaExpired" sitekey="6LelAd4ZAAAAAIj24IrngLRQbQFwzPvcCk9V8zFN" :loadRecaptchaScript="true">
                                            </vue-recaptcha>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" v-on:click="registerData" :disabled="form.isDisabled"
                                                class="btn btn-block btn-lg btn-primary btn-primary-3d btn-push" id="btnSubmit">Daftar
                                                Sekarang</button>
                                        </div>
                                    </form>
                                    <div class="d-block mt-3">
                                        <small>Kesulitan mendaftarkan sekolah? <router-link to="register#help" @click.native="scrollFix('#help')">Gunakan bantuan dari
                                                kami</router-link></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import axios from 'axios'
    import lozad from 'lozad'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import VueRecaptcha from 'vue-recaptcha'
    
    export default {
        name: 'Home',
        components: {
            VueRecaptcha,
            'footer-components': Footer,
            'help-cta-components': HelpCta
        },
        metaInfo: {
            title: 'Register',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        data() {
            return {
                form: {
                    role: 'kepsek',
                    title: 'Bapak',
                    full_name: '',
                    email: '',
                    phone: '',
                    school_name: '',
                    captcha : '',
                    isDisabled : true
                }
            }
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            registerData() {
                const role = this.form.role
                const title = this.form.title
                const full_name = this.form.full_name
                const email = this.form.email
                const phone = this.form.phone
                const school_name = this.form.school_name
                
                if (role == '' || title == '' || full_name == '' || email == '' || phone == '' || school_name == '') {
                    console.log("Harap lengkapi form pendaftar!");
                    document.getElementById("registerForm").classList.add("was-validated");

                } else {                    
                    if(this.form.captcha != '') {
                        document.getElementById("btnSubmit").disabled = true;                    
                        document.getElementById("btnSubmit").innerHTML='<i class="fa fa-spinner fa-spin"></i> Sedang mengirim data…';

                        const formData = new FormData();
                        formData.append('role', role);
                        formData.append('title', title);
                        formData.append('full_name', full_name);
                        formData.append('email', email);
                        formData.append('phone', phone);
                        formData.append('school_name', school_name);
                        formData.append('g-recaptcha-response', this.form.captcha);
                        axios.post('https://webapi.scola.id/api/register/save_register', formData, {}).then(
                            res => {
                                if (res.status) {
                                    this.$router.push({
                                        name: 'register-success'
                                    });
                                    
                                    localStorage.setItem('title', title);
                                    localStorage.setItem('fullname', full_name);
                                    localStorage.setItem('currentDate', res.data.currentDate);

                                    document.getElementById("btnSubmit").disabled = false;                    
                                    document.getElementById("btnSubmit").innerHTML='Daftar Sekarang';

                                } else {
                                    alert("Gagal menyimpan data");

                                    document.getElementById("btnSubmit").disabled = false;                    
                                    document.getElementById("btnSubmit").innerHTML='Daftar Sekarang';

                                }
                            }).catch(err => {
                            alert("Terjadi kesalahan, silahkan coba beberapa saat lagi", err);

                            document.getElementById("btnSubmit").disabled = false;                    
                            document.getElementById("btnSubmit").innerHTML='Daftar Sekarang';
                        });
                    }
                }
            },
            onCaptchaVerify(response) {
                // console.log('Verify: ' + response)
                this.form.captcha = response;
                this.form.isDisabled = false;
            },
            onCaptchaExpired() {
                console.log('Expired')
            },
            resetRecaptcha () {
                this.$refs.recaptcha.reset() // Direct call reset method
            },
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }

    }
</script>