<template>
    <div class="content">
        <header-components activeClass="content"></header-components>

        <section class="section section-two-side push-top">
            <div class="container">
                <div class="block">
                    <h2>PT. Inovasi Edukasi Bangsa Terms of Service</h2>
                    <h3>1. Terms</h3>
                    <p>By accessing the website and mobile apps at <a href="https://scola.id">https://scola.id</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website and mobile apps are protected by applicable copyright and trademark law.</p>
                    <h3>2. Use License</h3>
                    <ol type="a">
                    <li>Permission is granted to temporarily download one copy of the materials (information or software) on PT. Inovasi Edukasi Bangsa's website and mobile apps for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    <ol type="i">
                        <li>modify or copy the materials;</li>
                        <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                        <li>attempt to decompile or reverse engineer any software contained on PT. Inovasi Edukasi Bangsa's website and mobile apps;</li>
                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                        <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ol>
                        </li>
                    <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by PT. Inovasi Edukasi Bangsa at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                    </ol>
                    <h3>3. Disclaimer</h3>
                    <ol type="a">
                    <li>The materials on PT. Inovasi Edukasi Bangsa's website and mobile apps are provided on an 'as is' basis. PT. Inovasi Edukasi Bangsa makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                    <li>Further, PT. Inovasi Edukasi Bangsa does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website and mobile apps or otherwise relating to such materials or on any sites linked to this site.</li>
                    </ol>
                    <h3>4. Limitations</h3>
                    <p>In no event shall PT. Inovasi Edukasi Bangsa or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on PT. Inovasi Edukasi Bangsa's website and mobile apps, even if PT. Inovasi Edukasi Bangsa or a PT. Inovasi Edukasi Bangsa authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                    <h3>5. Accuracy of materials</h3>
                    <p>The materials appearing on PT. Inovasi Edukasi Bangsa's website and mobile apps could include technical, typographical, or photographic errors. PT. Inovasi Edukasi Bangsa does not warrant that any of the materials on its website and mobile apps are accurate, complete or current. PT. Inovasi Edukasi Bangsa may make changes to the materials contained on its website and mobile apps at any time without notice. However PT. Inovasi Edukasi Bangsa does not make any commitment to update the materials.</p>
                    <h3>6. Links</h3>
                    <p>PT. Inovasi Edukasi Bangsa has not reviewed all of the sites linked to its website and mobile apps and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by PT. Inovasi Edukasi Bangsa of the site. Use of any such linked website and mobile apps is at the user's own risk.</p>
                    <h3>7. Modifications</h3>
                    <p>PT. Inovasi Edukasi Bangsa may revise these terms of service for its website and mobile apps at any time without notice. By using this website and mobile apps you are agreeing to be bound by the then current version of these terms of service.</p>
                    <h3>8. Governing Law</h3>
                    <p>These terms and conditions are governed by and construed in accordance with the laws of Bandung, Indonesia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import lozad from 'lozad'
    export default {
        name: 'terms-of-service',
        components: {
            'header-components': Header
        },
        metaInfo: {
            title: 'Terms of Service',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
        }
    }
</script>