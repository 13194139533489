<template>
  <div class="blog-read">
    <header-components activeClass="program"></header-components>

    <!-- Banner -->
    <div class="home-banner banner-program home-banner-sm">
        <div class="container px-lg-5">
            <div class="row">
                <div class="col-lg-6 col-md-12 text-center-md">
                    <div class="home-banner--text">
                        <h1 class="mb-4 font-weight-bolder">Program Scola untuk Sekolah Masa Depan</h1>
                        <router-link to="#programList" @click.native="scrollFix('#programList')" class="btn btn-primary px-5">Lihat Program</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="programList" class="section section-lg">
        <div class="container">
            <div class="h2 d-block mb-4 font-weight-normal pb-3 border-bottom">4 program tersedia</div>
            <div class="row justify-content-center">
                <div class="d-none col-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/program-banner-1.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Program SMK Digital</h2>
                            <p class="small">Program SMK Digital dari Scola LMS yang bertujuan untuk ikut berperan aktif dalam membantu SMK BISA-HEBAT. </p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="d-block">
                                <button type="button" data-toggle="modal" data-target="#smkDigital" class="btn btn-outline-primary btn-block">Lihat Program</button>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXYEEx_FvkeWpYQCufQoONdHKOuWOo6et9eLjrVMxFW_ksjA/viewform" target="_blank" class="btn btn-primary btn-block">Daftar Program SMK Digital</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/program-sekolah-digital-banner.png" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Sekolah Digital</h2>
                            <p class="text-sm">Merupakan program dari Scola bagi sekolah-sekolah ingin bergabung menjadi sekolah mitra untuk mendapatkan layanan sistem pembelajaran online untuk mendukung sekolah di era pendidikan digital.</p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="row">
                                <div class="col">
                                    <a href="https://www.canva.com/design/DAEk_gePi3g/UoN97YAFfjvbZBbGZM_JAA/view?utm_content=DAEk_gePi3g&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent" class="btn btn-outline-primary btn-block" target="_blank">Lihat Program</a>
                                </div>
                                <div class="col">
                                    <a href="https://bit.ly/scola-sekolahdigital" target="_blank" class="btn btn-primary px-3 btn-block">Daftar Program</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/banner-kelas-digital.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Kelas Digital</h2>
                            <p class="text-sm">Program Kelas Digital merupakan terobosan terbaru dari Scola LMS yang bertujuan dalam membantu tenaga pengajar.</p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="row">
                                <div class="col">
                                    <button type="button" data-toggle="modal" data-target="#kelasDigital" class="btn btn-outline-primary btn-block">Lihat Program</button>
                                </div>
                                <div class="col">
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScVwRtufiuwBUtF7GvSPL_e3ZYwi0YHrcqO-G_HjlTMT53Wqg/viewform" target="_blank" class="btn btn-primary px-3 btn-block">Daftar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/program-sekolah-masa-depan-banner.png" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Sekolah Masa Depan</h2>
                            <p class="text-sm">Merupakan program dari Scola & PesonaEdu untuk memberikan <i class="font-weight-bold">one-stop solution</i> untuk pendidikan digital bagi sekolah yang bergabung di program.</p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="row">
                                <div class="col">
                                    <a href="https://www.canva.com/design/DAEJEUmNkMI/kKfvNioPLEv4q-uB8z7K9g/view?utm_content=DAEJEUmNkMI&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent" target="_blank" class="btn btn-outline-primary btn-block">Lihat Program</a>
                                </div>
                                <div class="col">
                                    <a href="https://bit.ly/scola-sekolahmasadepan" target="_blank" class="btn btn-primary px-3 btn-block">Daftar Program</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/program-guru-kolaborator-banner.png" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Guru Kolaborator</h2>
                            <p class="text-sm">Adalah program yang dirancang oleh Scola LMS dan perusahaan mitra untuk memberikan dukungan kepada dunia pendidikan melalui guru dan tenaga pendidikan di sekolah.</p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="row">
                                <div class="col">
                                    <a href="https://www.canva.com/design/DAEn5v_vkjE/3vg5T6mX1CRKnRLwm56pCw/watch?utm_content=DAEn5v_vkjE&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu" class="btn btn-outline-primary btn-block" target="_blank">Lihat Program</a>
                                </div>
                                <div class="col">
                                    <a href="https://bit.ly/scola-gurukolaborator" target="_blank" class="btn btn-primary px-3 btn-block">Daftar Program</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-none col-md-4 mb-3">
                    <div class="card border lift mb-0 h-100" style="overflow:hidden;">
                        <img src="/images/program-banner-3.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <h2 class="mb-2">Sekolah Masa Depan</h2>
                            <p class="small">Wujudkan sekolah masa depanmu bersama Scola! Scola LMS mempunyai visi menciptakan Sekolah masa depan yang mempunyai kualitas...</p>
                        </div>
                        <div class="card-footer pb-4">
                            <div class="d-block">
                                <button type="button" data-toggle="modal" data-target="#sekolahMasaDepan" class="btn btn-outline-primary btn-block">Lihat Program</button>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwfHdogr2Om5wHpw-xEDImStbIux-HCRQl4QPQQT3ViirkUA/viewform" target="_blank" class="btn btn-primary px-3 btn-block">Daftar Program Kelas Digital</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none row justify-content-center">
                <div class="col-md-8 px-lg-5">
                    
                    <!-- SMK DIGITAL -->
                    <div class="card border lift mb-4" style="overflow:hidden;">
                        <img src="/images/program-banner-1.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <div>
                                    <h1 class="m-0">Program SMK Digital</h1>
                                </div>
                                <div>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXYEEx_FvkeWpYQCufQoONdHKOuWOo6et9eLjrVMxFW_ksjA/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program SMK Digital</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="lined lined-yellow">
                                        <p class="text-xs">
                                            Program SMK Digital dari Scola LMS yang bertujuan untuk ikut berperan aktif dalam membantu SMK BISA-HEBAT. Program SMK Digital kami bertujuan untuk menyiapkan SMK siap menjadi sekolah masa depan dengan menerapkan inovasi pada ekosistem pendidikan yang lebih efektif dan interaktif, menggabungkan unsur technology, education dan creativity.
                                        </p>
                                        <p class="text-xs">
                                            Menciptakan lulusan SDM yang unggul, kompeten dan profesional sesuai tuntutan dunia kerja dan kebutuhan Dunia Industri. Bekerja sama dengan pihak Industri untuk menciptakan kurikulum vocational yang efektif sesuai kebutuhan Dunia Kerja.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <vsa-list>
                                <!-- Here you can use v-for to loop through items  -->
                                <vsa-item>
                                    <vsa-heading>
                                        Keuntungan mengunakan SMK Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>Penggunaan Scola LMS</li>
                                            <li>Menjadi sekolah Mitra</li>
                                            <li>Bekerja sama dengan Dunia Industri</li>
                                            <li>Kurikulum belajar siap kerja dan penyerapan tenaga kerja</li>
                                            <li>Pelatihan dan pendampingan selama Pra Learning</li>
                                            <li>Group Nasional SMK Digital program</li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Syarat bergabung dengan SMK Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>Komitmen mengikuti program</li>
                                            <li>Kontrak minimal 2 tahun</li>
                                            <li>Sekolah akan di evaluasi setiap 3 bulan</li>
                                            <li>Wajib menjadi sekolah percontohan</li>
                                            <li>Bersedia mengikuti program prakerin dan magang</li>
                                            <li>Bersedia bekerja pada partner Industri</li>
                                            <li>Mendukung Scola LMS dalam branding</li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Alur bergabung SMK Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <img src="/images/program-banner-2.png" class="w-100">
                                    </vsa-content>
                                </vsa-item>
                            </vsa-list>
                        </div>
                    </div>

                    <!-- KElAS DIGITAL -->
                    <div class="card border lift mb-4" style="overflow:hidden;">
                        <img src="/images/program-banner-2.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <div>
                                    <h1 class="m-0">Kelas Digital</h1>
                                </div>
                                <div>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScVwRtufiuwBUtF7GvSPL_e3ZYwi0YHrcqO-G_HjlTMT53Wqg/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program Kelas Digital</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="lined lined-yellow">
                                        <p class="text-xs">
                                            Program Kelas Digital merupakan terobosan terbaru dari Scola LMS yang bertujuan dalam membantu tenaga pengajar terkait kendala susahnya mendapatkan platform pembelajaran yang sesuai dengan kondisi saat ini, Maka untuk meningkatan metode pendidikan kami berusaha memberikan platform pendidikan yang canggih dan praktis serta di kembangkan berdasarkan hasil riset dari guru di Indonesia.
                                        </p>
                                        <p class="text-xs">
                                            Kelas Digital dirancang untuk mempermudah pekerjaan guru, menyiapkan kelas belajar digital yang efektif hingga solusi terbaik bagi partner mengajar kepada siswa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <vsa-list>
                                <!-- Here you can use v-for to loop through items  -->
                                <vsa-item>
                                    <vsa-heading>
                                        Keuntungan mengunakan Kelas Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>Penggunaan Scola LMS</li>
                                            <li>Menjadi Guru Champion Scola</li>
                                            <li>Program pelatihan pengunaan Scola</li>
                                            <li>E-Sertifikat untuk guru digital</li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Syarat bergabung dengan Kelas Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>Digunakan oleh Tenaga Pengajar (Guru, Dosen, Pengajar, Trainier dan lainnya)</li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Alur bergabung Kelas Digital
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <img src="/images/program-banner-4.png" class="w-100">
                                    </vsa-content>
                                </vsa-item>
                            </vsa-list>
                        </div>
                    </div>

                    <!-- SEKOLAH MASA DEPAN -->
                    <div class="card border lift mb-4" style="overflow:hidden;">
                        <img src="/images/program-banner-3.jpg" class="w-100 border-bottom">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <div>
                                    <h1 class="m-0">Sekolah Masa Depan</h1>
                                </div>
                                <div>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwfHdogr2Om5wHpw-xEDImStbIux-HCRQl4QPQQT3ViirkUA/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program Kelas Digital</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="lined lined-yellow">
                                        <div class="d-block text-xs">
                                           <p>Wujudkan sekolah masa depanmu bersama Scola! Scola LMS mempunyai visi menciptakan Sekolah masa depan yang mempunyai kualitas pembelajaran yang tepat dan terstruktur. Dengan program ini konsep belajar di sekolah semakin mudah, saling bersinergi dan sesuai dengan tantangan pendidikan modern.</p>
                                            <p>Program Sekolah Masa Depan membantu menciptakan kualitas pembelajaran semakin baik sehingga dapat membantu siswa mendapatkan nilai yang bagus di barengi dengan peningkatan softskill. Siswa mendapatkan modul-modul dari partner kami yang fokus dalam pengembangan dan inovasi kualitas pendidikan di Indonesia. Hal ini bertujuan agar siswa dapat mendapatkan nilai terbaik dan sukses lolos ke perguruan tinggi.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <vsa-list>
                                <!-- Here you can use v-for to loop through items  -->
                                <vsa-item>
                                    <vsa-heading>
                                        Keuntungan Program Sekolah Masa Depan
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>
                                                <span class="font-weight-bolder">Penggunaan Scola LMS</span><br>
                                                <p>Scola memberikan akses pengunaan ke sekolah yang bergabung dengan Sekolah Mitra. Selama program berlangsung sekolah akan mendapatkan banyak keuntungan yang akan membantu menjadi sekolah digital. </p>
                                            </li>
                                            <li>
                                                <span class="font-weight-bolder">Pelatihan & Pendampingan Scola</span><br>
                                                <p>Pelatihan & Pendampingan kepada sekolah yang akan mengunakan program aplikasi Scola LMS. Hal ini membantu sekolah siap dalam proses implementasi dan penggunaan aplikasi Scola LMS.</p>
                                            </li>
                                            <li>
                                                <span class="font-weight-bolder">Program Pelatihan Guru</span><br>
                                                <p>
                                                    Scola menyediakan training peningkatan kompetensi kepada guru - guru di sekolah. Kami siap membantu sekolah dalam meningkatkan pelayanan menuju era digital. Guru yang mengikuti training akan mendapatkan kesempatan mengikuti program sertifikasi Guru Digital.
                                                </p>
                                            </li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Syarat bergabung dengan Program Sekolah Masa Depan
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <ul>
                                            <li>Komitmen mengikuti program </li>
                                            <li>Kontrak minimal 2 tahun</li>
                                            <li>Sekolah akan di evaluasi setiap 6 bulan</li>
                                            <li>Wajib menjadi sekolah percontohan</li>
                                            <li>Mendukung Scola LMS dalam branding</li>
                                            <li>Menjadi Sekolah dalam pengembangan kualitas pembelajaran</li>
                                        </ul>
                                    </vsa-content>
                                </vsa-item>
                                <vsa-item>
                                    <vsa-heading>
                                        Alur bergabung Program Sekolah Masa Depan
                                    </vsa-heading>
                                
                                    <vsa-content>
                                        <img src="/images/program-banner-6.png" class="w-100">
                                    </vsa-content>
                                </vsa-item>
                            </vsa-list>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- #### MODALS #### -->
    <!-- Modal SMK Digital -->
    <div class="modal fade" id="smkDigital" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background:transparent;">
                <div class="card border-0 mb-0" style="overflow:hidden;">
                    <img src="/images/program-banner-1.jpg" class="w-100 border-bottom">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h1 class="m-0">Program SMK Digital</h1>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXYEEx_FvkeWpYQCufQoONdHKOuWOo6et9eLjrVMxFW_ksjA/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program SMK Digital</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lined lined-yellow">
                                    <p class="text-xs">
                                        Program SMK Digital dari Scola LMS yang bertujuan untuk ikut berperan aktif dalam membantu SMK BISA-HEBAT. Program SMK Digital kami bertujuan untuk menyiapkan SMK siap menjadi sekolah masa depan dengan menerapkan inovasi pada ekosistem pendidikan yang lebih efektif dan interaktif, menggabungkan unsur technology, education dan creativity.
                                    </p>
                                    <p class="text-xs">
                                        Menciptakan lulusan SDM yang unggul, kompeten dan profesional sesuai tuntutan dunia kerja dan kebutuhan Dunia Industri. Bekerja sama dengan pihak Industri untuk menciptakan kurikulum vocational yang efektif sesuai kebutuhan Dunia Kerja.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <vsa-list>
                            <!-- Here you can use v-for to loop through items  -->
                            <vsa-item>
                                <vsa-heading>
                                    Keuntungan mengunakan SMK Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Penggunaan Scola LMS</li>
                                        <li>Menjadi sekolah Mitra</li>
                                        <li>Bekerja sama dengan Dunia Industri</li>
                                        <li>Kurikulum belajar siap kerja dan penyerapan tenaga kerja</li>
                                        <li>Pelatihan dan pendampingan selama Pra Learning</li>
                                        <li>Group Nasional SMK Digital program</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Syarat bergabung dengan SMK Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Komitmen mengikuti program</li>
                                        <li>Kontrak minimal 2 tahun</li>
                                        <li>Sekolah akan di evaluasi setiap 3 bulan</li>
                                        <li>Wajib menjadi sekolah percontohan</li>
                                        <li>Bersedia mengikuti program prakerin dan magang</li>
                                        <li>Bersedia bekerja pada partner Industri</li>
                                        <li>Mendukung Scola LMS dalam branding</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Alur bergabung SMK Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <img src="/images/program-banner-2.png" class="w-100">
                                </vsa-content>
                            </vsa-item>
                        </vsa-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Kelas Digital -->
    <div class="modal" id="kelasDigital" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background:transparent;">
                <div class="card border-0 mb-0" style="overflow:hidden;">
                    <img src="/images/banner-kelas-digital.jpg" class="w-100 border-bottom">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h1 class="m-0">Kelas Digital</h1>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScVwRtufiuwBUtF7GvSPL_e3ZYwi0YHrcqO-G_HjlTMT53Wqg/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program Kelas Digital</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lined lined-yellow">
                                    <p class="text-xs">
                                        Program Kelas Digital merupakan terobosan terbaru dari Scola LMS yang bertujuan dalam membantu tenaga pengajar terkait kendala susahnya mendapatkan platform pembelajaran yang sesuai dengan kondisi saat ini, Maka untuk meningkatan metode pendidikan kami berusaha memberikan platform pendidikan yang canggih dan praktis serta di kembangkan berdasarkan hasil riset dari guru di Indonesia.
                                    </p>
                                    <p class="text-xs">
                                        Kelas Digital dirancang untuk mempermudah pekerjaan guru, menyiapkan kelas belajar digital yang efektif hingga solusi terbaik bagi partner mengajar kepada siswa.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <vsa-list>
                            <!-- Here you can use v-for to loop through items  -->
                            <vsa-item>
                                <vsa-heading>
                                    Keuntungan mengunakan Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Penggunaan Kelas Digital selama satu semester</li>
                                        <li>Menjadi Guru Partnership</li>
                                        <li>Program pelatihan</li>
                                        <li>E-Sertifikat untuk guru digital</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Syarat bergabung dengan Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Digunakan oleh tenaga pendidik dalam instansi pendidikan (Guru dan Dosen)</li>
                                        <li>Terbatas untuk 50 guru hingga awal tahun ajar</li>
                                        <li>Akses diberikan untuk 1 guru 1 sekolah</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Alur bergabung Kelas Digital
                                </vsa-heading>
                            
                                <vsa-content>
                                    <img src="/images/alur-bergabung-kelas-digital.png" class="w-100">
                                </vsa-content>
                            </vsa-item>
                        </vsa-list>
                        <div class="d-flex align-items-center justify-content-end mt-3">
                          <button type="button" class="btn btn-link btn-sm" data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Sekolah Masa Depan -->
    <div class="modal fade" id="sekolahMasaDepan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background:transparent;">
                <div class="card border-0 mb-0" style="overflow:hidden;">
                    <img src="/images/program-banner-3.jpg" class="w-100 border-bottom">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h1 class="m-0">Sekolah Masa Depan</h1>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwfHdogr2Om5wHpw-xEDImStbIux-HCRQl4QPQQT3ViirkUA/viewform" target="_blank" class="btn btn-outline-primary px-3 ">Daftar Program Kelas Digital</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lined lined-yellow">
                                    <div class="d-block text-xs">
                                        <p>Wujudkan sekolah masa depanmu bersama Scola! Scola LMS mempunyai visi menciptakan Sekolah masa depan yang mempunyai kualitas pembelajaran yang tepat dan terstruktur. Dengan program ini konsep belajar di sekolah semakin mudah, saling bersinergi dan sesuai dengan tantangan pendidikan modern.</p>
                                        <p>Program Sekolah Masa Depan membantu menciptakan kualitas pembelajaran semakin baik sehingga dapat membantu siswa mendapatkan nilai yang bagus di barengi dengan peningkatan softskill. Siswa mendapatkan modul-modul dari partner kami yang fokus dalam pengembangan dan inovasi kualitas pendidikan di Indonesia. Hal ini bertujuan agar siswa dapat mendapatkan nilai terbaik dan sukses lolos ke perguruan tinggi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <vsa-list>
                            <!-- Here you can use v-for to loop through items  -->
                            <vsa-item>
                                <vsa-heading>
                                    Keuntungan Program Sekolah Masa Depan
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>
                                            <span class="font-weight-bolder">Penggunaan Scola LMS</span><br>
                                            <p>Scola memberikan akses pengunaan ke sekolah yang bergabung dengan Sekolah Mitra. Selama program berlangsung sekolah akan mendapatkan banyak keuntungan yang akan membantu menjadi sekolah digital. </p>
                                        </li>
                                        <li>
                                            <span class="font-weight-bolder">Pelatihan & Pendampingan Scola</span><br>
                                            <p>Pelatihan & Pendampingan kepada sekolah yang akan mengunakan program aplikasi Scola LMS. Hal ini membantu sekolah siap dalam proses implementasi dan penggunaan aplikasi Scola LMS.</p>
                                        </li>
                                        <li>
                                            <span class="font-weight-bolder">Program Pelatihan Guru</span><br>
                                            <p>
                                                Scola menyediakan training peningkatan kompetensi kepada guru - guru di sekolah. Kami siap membantu sekolah dalam meningkatkan pelayanan menuju era digital. Guru yang mengikuti training akan mendapatkan kesempatan mengikuti program sertifikasi Guru Digital.
                                            </p>
                                        </li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Syarat bergabung dengan Program Sekolah Masa Depan
                                </vsa-heading>
                            
                                <vsa-content>
                                    <ul>
                                        <li>Komitmen mengikuti program </li>
                                        <li>Kontrak minimal 2 tahun</li>
                                        <li>Sekolah akan di evaluasi setiap 6 bulan</li>
                                        <li>Wajib menjadi sekolah percontohan</li>
                                        <li>Mendukung Scola LMS dalam branding</li>
                                        <li>Menjadi Sekolah dalam pengembangan kualitas pembelajaran</li>
                                    </ul>
                                </vsa-content>
                            </vsa-item>
                            <vsa-item>
                                <vsa-heading>
                                    Alur bergabung Program Sekolah Masa Depan
                                </vsa-heading>
                            
                                <vsa-content>
                                    <img src="/images/program-banner-6.png" class="w-100">
                                </vsa-content>
                            </vsa-item>
                        </vsa-list>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <cta-components></cta-components>
    <footer-components></footer-components>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import Cta from '@/components/Cta.vue'
  import lozad from 'lozad'
  import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
    } from 'vue-simple-accordion';

  export default {
    name: 'BlogRead',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'cta-components': Cta,
      VsaList,
      VsaItem,
      VsaHeading,
      VsaContent
    },
    metaInfo: {
      title: 'Program Scola LMS',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>
