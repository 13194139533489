<template>
  <div class="about">
    <header-components activeClass="about"></header-components>

    <!-- ####### Scola Punchline ####### -->
    <div class="section-two-side push-top">
      <div class="section-item section-item-bg section-item-bg-scola section-bg-w-animation"
        style="background-image:url('images/pexels-photo-4492135.jpeg');">
          <img data-src="https://assets.scola.id/newwebsite/images/element-topic-1-new.svg" class="element-1 lozad">
          <img data-src="https://assets.scola.id/newwebsite/images/element-topic-2-new.svg" class="element-2 lozad" style="animation-delay:-2s;">
          <img data-src="https://assets.scola.id/newwebsite/images/element-schedule-1-new.svg" class="element-3 lozad" style="animation-delay:-4s;">
          <div class="element-foreground-1 lozad" data-background-image="https://assets.scola.id/images/element-foreground-1.png"></div>
      </div>
      <div class="section-item section-item-text">
        <div class="d-block pr-md-5 pr-0 pt-md-4">
          <div class="font-weight-bolder text-xlg mb-5">Solusi Manajemen Pembelajaran Digital yang Tepat Untuk Sekolah Anda</div>
          <p class="mb-4 text-sm lined lined-yellow">Kami membantu membangun sistem
            pembelajaran online yang mudah digunakan untuk belajar dari sekolah ke rumah. Scola LMS menyediakan konten-konten e-learning dan mengunakan metode pembelajaran menarik di era
            pendidikan digital.</p>
          <h3 class="mb-0">Bersama Scola LMS, wujudkan sekolah masa depan Anda</h3>
        </div>
      </div>
    </div>
    <!-- <div class="d-block bg-primary-gradient" style="height: 12px;"></div> -->

    <!-- Quick Link -->
    <div class="d-block py-4 bg-lighter quick-link">
      <div class="container">
        <div class="row">
          <div class="col-md-12 px-lg-5">
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex link-list text-sm font-weight-bolder">
                <router-link to="#PenggunaScola" @click.native="scrollFix('#PenggunaScola')" class="mr-5 text-primary link-naked">Pengguna Scola</router-link>
                <router-link to="#ProsesPembelajaran" @click.native="scrollFix('#ProsesPembelajaran')" class="mr-5 text-primary link-naked">Proses Pembelajaran</router-link>
                <router-link to="#ProsesBergabung" @click.native="scrollFix('#ProsesBergabung')" class="text-primary link-naked">Proses Bergabung</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="PenggunaScola" class="section section-lg">
      <div class="container mb-4">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <div class="title-lg mb-4 font-weight-bolder"> Pengguna Scola LMS</div>
            <div class="font-weight-normal">Kini Anda dapat menghubungkan kebutuhan Guru, Siswa, Orang Tua, Admin serta Kepala Sekolah dengan menciptakan kolaborasi efektif dalam pembelajaran digital.</div>
          </div>
        </div>
      </div>
      <img data-src="https://assets.scola.id/images/scola-user-all.png" class="w-100 lozad" alt="Scola User All">
    </div>

    <div id="ProsesPembelajaran" class="section section-lg bg-lighter">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-8 text-center">
            <div class="title-lg font-weight-bolder mb-4"> Model Pembelajaran dengan Scola LMS </div>
            <!-- <p>Kami membantu membangun sistem pembelajaran online yang mudah digunakan, saling terhubung (kolaboratif) serta menjaga kelangsungan belajar dari sekolah ke rumah.</p> -->
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <div class="d-block mb-5 border-bottom pb-5">
              <div class="number number-color-1 mb-3">1</div>
              <h2 class="text-xlg mb-2">Inovasi Kelas Online</h2>
              <p class="text-md">Membantu sekolah untuk memiliki kelas masa depan dengan teknologi canggih yang akan
                memberikan pengalaman belajar menyenangkan dan menarik.</p>
            </div>
            <div class="d-block mb-5 border-bottom pb-5">
              <div class="number number-color-2 mb-3">2</div>
              <h2 class="text-xlg mb-2">Penerapan Pembelajaran Campuran</h2>
              <p class="text-md">Peserta didik mempelajari konten belajar di aplikasi Scola LMS diluar kelas atau secara
                mandiri, kemudian melakukan diskusi dikelas.</p>
            </div>

            <div class="d-block mb-5 border-bottom pb-5">
              <div class="number number-color-3 mb-3">3</div>
              <h2 class="text-xlg mb-2">Pembelajaran Personal</h2>
              <p class="text-md">Membantu menyesuaikan proses pembelajaran yang disesuaikan dengan kemampuan dan
                kebutuhan setiap siswa dengan tetap melibatkan pihak sekolah dan juga orang tua.</p>
            </div>
            <div class="d-block mb-5 border-bottom pb-5">
              <div class="number number-color-4 mb-3">4</div>
              <h2 class="text-xlg mb-2">Kelas Interaktif</h2>
              <p class="text-md">Pembelajaran dapat dilakukan secara jarak jauh/online dimana para siswa dan guru dapat
                saling berinteraksi dengan baik dan terkendali oleh sekolah.</p>
            </div>
            <div class="d-block mb-5">
              <div class="number number-color-5 mb-3">5</div>
              <h2 class="text-xlg mb-2">Materi Pembelajaran</h2>
              <p class="text-md">Scola LMS menyediakan konten pembelajaran yang sesuai dengan K13 seperti video materi
                belajar, soal-soal kuis dan ujian yang dapat diakses langsung oleh sekolah dan guru.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-xl bg-dark card-style-top-right pb-3">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="row align-items-center">
              <div class="col-md-6 text-center mb-md-0 mb-5">
                <img data-src="https://assets.scola.id/newwebsite/images/scola-untuk-indonesia-new.svg" width="340" class="lozad">
              </div>
              <div class="col-md-6 text-center-sm">
                <p class="font-weight-bolder text-warning">Visi Kami</p>
                <p class="text-white">Kami memiliki visi agar kualitas SDM Indonesia dapat meningkat, sehingga Scola LMS memiliki visi besar bahwa kualitas SDM Indonesia dapat meningkat sehingga Program Pemerintah Indonesia Golden Age 2045 dapat tercapai. Di tahun 2045, 70% usia masyarakat Indonesia ada pada usia produktif. Potensi untuk masuk di 5 Negara Maju di dunia sangat besar.</p><p class="text-white">Kami percaya dengan meningkatkan kualitas siswa & guru melalui metode belajar yang menyenangkan, harapan besar tersebut dapat tercapai.</p>
                <br>
                <router-link to="register" class="btn btn-primary px-4 mb-md-0 mb-4 btn-block-mobile">Daftar Sekarang</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-lg bg-dark text-white">
      <div class="container">
        <div class="d-block">
          <div class="title-md d-block text-center mb-4">Komitmen Scola untuk meningkatkan mutu pendidikan Indonesia.</div>
          <div class="row row-w-line text-sm">
            <div class="col-w-line col-md-3 text-center-sm mb-4 mb-md-0">
              <span class="fe fe-award title-md text-warning mb-3 d-inline-block"></span><br>
              Pemenang ke II di Bidang Usaha Teknologi Digital Wirausaha Muda Mandiri 2018 oleh Bank Mandiri
            </div>
            <div class="col-w-line col-md-3 text-center-sm mb-4 mb-md-0">
              <span class="fe fe-award title-md text-warning mb-3 d-inline-block"></span><br>
              Startup terpilih dalam Program BIP Bekraf 2018
            </div>
            <div class="col-w-line col-md-3 text-center-sm mb-4 mb-md-0">
              <span class="fe fe-award title-md text-warning mb-3 d-inline-block"></span><br>
              Best Pitching Startup 2017 oleh Pedals.id
            </div>
            <div class="col-w-line col-md-3 text-center-sm mb-4 mb-md-0">
              <span class="fe fe-award title-md text-warning mb-3 d-inline-block"></span><br>
              Inovator di Program IBT 2017 yang dilaksanakan oleh Kemenristekdikti
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="ProsesBergabung" class="section section-lg bg-primary-gradient">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row justify-content-center mb-5 text-white">
              <div class="col-md-8 text-center">
                <div class="title-lg font-weight-bolder mb-4 px-md-0 px-5">Proses bergabung bersama Scola LMS</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-4">
                <div class="card h-100 mb-0">
                  <div class="card-body text-center py-5">
                    <div class="number number-grey mb-3">1</div>
                    <h2 class="text-lg mb-2">Daftarkan sekolah Anda</h2>
                    <p class="mb-0 text-sm">Isi formulir pendaftaran serta lengkapi dokumen pendukung untuk bergabung
                      bersama Scola LMS.</p>
                  </div>
                  <div class="card-footer pb-4">
                    <router-link to="register" target="_blank" class="btn btn-primary btn-primary-3d btn-push btn-block">Baca Formulir Pendaftaran</router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="card h-100 mb-0">
                  <div class="card-body text-center py-5">
                    <div class="number number-grey mb-3">2</div>
                    <h2 class="text-lg mb-2">Pengenalan & demo</h2>
                    <p class="mb-0 text-sm">Setelah formulir dan dokumen dilengkapi, selanjutnya kami akan memberikan
                      demo terkait akses dan fitur Scola LMS.</p>
                  </div>
                  <div class="card-footer pb-4">
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_376a6017e1c76492199a6d67ebe585052088bcb93c30a2a71f36ecd9231c4abf20200929101549.mp4" data-lity class="btn btn-primary btn-primary-3d btn-push btn-block">Lihat Video
                      Pengenalan</a>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="card h-100 mb-0">
                  <div class="card-body text-center py-5">
                    <div class="number number-grey mb-3">3</div>
                    <h2 class="text-lg mb-2">Implementasi</h2>
                    <p class="mb-0 text-sm">Setelah semua proses kerja sama selesai, tim kami akan membantu proses implementasi ke sekolah Anda.</p>
                  </div>
                  <div class="card-footer pb-4">
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_087440e22ad487e87677105b811325e4c2d63afff6bd8eb564af4816fcabb81420200929101137.mp4" data-lity class="btn btn-primary btn-primary-3d btn-push btn-block">Lihat Video
                      Implementasi</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <help-cta-components></help-cta-components>
    <footer-components></footer-components>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import lozad from 'lozad';

  export default {
    name: 'About',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'help-cta-components': HelpCta
    },
    metaInfo: {
      title: 'Tentang Kami',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>