<template>
    <div class="manajemen-sistem">
        <header-components activeClass="manajemen-sistem"></header-components>

        <!-- Banner -->
        <div class="home-banner banner-manajemen-sistem">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Sistem Manajemen</h1>
                            <h2 class="mb-0 font-weight-normal lined lined-yellow">Sekolah dapat dengan mudah mengelola
                                sistem pembelajaran,memonitor proses pembelajaran dan perkembangan aktifitas
                                pembelajaran di Scola LMS.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block bg-primary-gradient p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">
                        <router-link to="fitur-andalan" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="ujian-online" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Ujian Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="virtual-class" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Interaktif</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="manajemen-sistem" class="card m-0 text-white bg-primary">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Sistem Manajemen</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Quick Link -->
        <div class="d-block py-4 bg-lighter border-bottom quick-link">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 px-lg-5">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="d-flex link-list text-sm font-weight-bolder">
                                <router-link to="#featuresItem" @click.native="scrollFix('#featuresItem')" class="mr-5 text-primary">Layanan Fitur</router-link>
                                <router-link to="#keuntunganPengguna" @click.native="scrollFix('#keuntunganPengguna')" class="mr-5 text-primary">Keuntungan Pengguna</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Features -->
        <div id="featuresItem" class="section section-xl bg-white pt-5">
            <div class="container">
                <!-- <div class="row justify-content-center mb-5">
            <div class="col-md-10 text-center">
                <div class="title-lg font-weight-bolder mb-4"> Penguna Scola LMS </div>
            </div>
        </div> -->
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section section-lg">
                            <div class="d-block">
                                <img data-src="https://assets.scola.id/newwebsite/images/feature-manajemen-new.svg" class="w-100 lozad">
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/personal-data.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelola Pengguna (Siswa, Guru, Orang Tua)
                                    </div>
                                    <p class="mb-0">Admin dapat lebih mudah dalam mengelola data pengguna seperti
                                        merubah informasi user beserta password.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/list.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelola Kelas & Rombel</div>
                                    <p class="mb-0">Memudahkan admin dalam pengelolaan Rombel dan Kelas yang ada di
                                        sekolah.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/study.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelola Rencana Pembelajaran</div>
                                    <p class="mb-0">Admin dimudahkan dalam pengelolaan kurikulum sekolah dimana admin
                                        dapat menyusun kurikulum sesuai K13 dan tambahan dari sekolah.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/report.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Report</div>
                                    <p class="mb-0">Memberikan kemudahan bagi admin dalam melihat nilai hasil
                                        pembelajaran yang sudah dilaksanakan oleh siswa pada kelas.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/study.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelola Kurikulum</div>
                                    <p class="mb-0">Mengelola fungsi kurikulum yang ada di sekolah.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/monitoring.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Monitoring Konten Guru</div>
                                    <p class="mb-0">Pihak Koordinator dapat dengan mudah melakukan tinjauan terkait
                                        konten yang sudah dibuatkan oleh guru ke masing-masing kelas yang sudah ada.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/list.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kelola Topik & Subtopik</div>
                                    <p class="mb-0">Admin dapat membuatkan topik dan subtopik agar materi yang dibuatkan
                                        oleh guru lebih tertata.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/calendar-2.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Jadwal Belajar</div>
                                    <p class="mb-0">Jadwal belajar memudahkan guru dan siswa dalam mengikuti semua kegiatan belajar sehingga terorganisir dengan baik.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/calendar-2.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Kalender Akademik</div>
                                    <p class="mb-0">Admin dapat menginformasikan kepada guru,siswa dan orang tua terkait aktifitas dan kegiatan sekolah.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img src="images/bill.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Informasi Pembayaran Sekolah</div>
                                    <p class="mb-0">Admin dapat memberikan informasi kepada pihak orang tua murid terkait dengan pembayaran sekolah.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="keuntunganPengguna" class="section section-lg bg-lighter">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Keuntungan Pengguna pada Sistem Manajemen </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-3.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Sekolah</div>
                                <p class="text-sm mb-3">Sekolah dapat lebih mudah mengelola sistem pembelajaran dan
                                    monitor proses pembelajaran.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img src="images/ilust-4.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Guru</div>
                                <p class="text-sm mb-3">Guru dapat lebih mudah mengelola kelas yang sudah terdaftar di
                                    akunnya.</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-1.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Siswa</div>
                        <p class="text-sm mb-3">Siswa dapat dengan mudah mengerjakan ujian secara online dimanapun dan kapanpun.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-2.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Orang Tua</div>
                        <p class="text-sm mb-3">Orang tua dapat melihat ujian apa saja yang harus dikerjakan oleh siswa.</p>
                    </div>
                </div>
            </div> -->
                </div>
            </div>
        </div>

        <div class="container px-lg-5">
            <div class="d-block pb-3 pt-3 border-bottom">
                <div class="d-flex justify-content-between">
                    <router-link to="virtual-class" class="pagination-link">
                        <div class="text-sm d-block mb-2"><span class="fe fe-arrow-left mr-2"></span>Sebelumnya</div>
                        <div class="d-block font-weight-bolder text-lg">KELAS INTERAKTIF</div>
                    </router-link>
                    <router-link to=fitur-andalan class="pagination-link pagination-next">
                        <div class="text-sm d-block mb-2">Selanjutnya<span class="fe fe-arrow-right ml-2"></span></div>
                        <div class="d-block font-weight-bolder text-lg">KELAS ONLINE</div>
                    </router-link>
                </div>
            </div>
        </div>
        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Cta from '@/components/Cta.vue'
    import lozad from 'lozad'

    export default {
        name: 'manajemen-sistem',
        components: {
            'header-components': Header,
            'footer-components': Footer,
            'help-cta-components': HelpCta,
            'cta-components': Cta
        },
        metaInfo: {
            title: 'Manajemen Sistem',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>