<template>
  <div class="best-features">
    <header-components activeClass="best-features"></header-components>

<!-- Banner -->
<div class="home-banner banner-kelas-online">
    <div class="container px-lg-5">
        <div class="row">
            <div class="col-lg-5 col-md-12 text-center-md">
                <div class="home-banner--text">
                    <h1 class="mb-4 font-weight-bolder">Kelas Online</h1>
                    <h2 class="mb-0 font-weight-normal lined lined-yellow">Memfasilitasi guru untuk menyampaikan materi, tugas dan ujian secara online dengan lebih mudah. Serta membantu siswa menyerap materi dengan cara yang menarik.</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-block bg-primary-gradient p-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <router-link to="fitur-andalan" class="card m-0 text-white bg-primary">
                    <div class="py-3 px-2 text-center">
                        <div class="d-block font-weight-bolder text-xs">Kelas Online</div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3">
                <router-link to="ujian-online" class="card m-0 text-dark">
                    <div class="py-3 px-2 text-center">
                        <div class="d-block font-weight-bolder text-xs">Ujian Online</div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3">
                <router-link to="virtual-class" class="card m-0 text-dark">
                    <div class="py-3 px-2 text-center">
                        <div class="d-block font-weight-bolder text-xs">Kelas Interaktif</div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3">
                <router-link to="manajemen-sistem" class="card m-0 text-dark">
                    <div class="py-3 px-2 text-center">
                        <div class="d-block font-weight-bolder text-xs">Sistem Manajemen</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>

<!-- Quick Link -->
<div class="d-block py-4 bg-lighter border-bottom quick-link">
    <div class="container">
        <div class="row">
            <div class="col-md-12 px-lg-5">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="d-flex link-list text-sm font-weight-bolder">
                        <router-link to="#featuresItem" @click.native="scrollFix('#featuresItem')" class="mr-5 text-primary">Layanan Fitur</router-link>
                        <router-link to="#keuntunganPengguna" @click.native="scrollFix('#keuntunganPengguna')" class="mr-5 text-primary">Keuntungan Pengguna</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Features -->
<div id="featuresItem" class="section section-xl bg-white pt-5">
    <div class="container">
        <!-- <div class="row justify-content-center mb-5">
            <div class="col-md-10 text-center">
                <div class="title-lg font-weight-bolder mb-4"> Penguna Scola LMS </div>
            </div>
        </div> -->
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="section section-lg">
                    <div class="d-block">
                        <div class="feature-img-1">
                            <img data-src="https://assets.scola.id/images/feature-kelas-online.svg" alt="Feature 1" class="w-100 lozad">
                            <img data-src="https://assets.scola.id/images/feature-kelas-online-addon-1.svg" class="img-animate lozad">
                        </div>    
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/study.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Materi Belajar</div>
                            <p class="text-md mb-0">Memudahkan guru dalam memberikan materi pembelajaran yang bervariasi dalam bentuk e-book atau video pembelajaran.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/list.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Tugas</div>
                            <p class="text-md mb-0">Pengelolaan tugas menjadi lebih mudah untuk guru dan siswa dari mulai pemberian tugas, pengumpulan sampai ke penilaian.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/quiz.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Kuis & Ujian</div>
                            <p class="text-md mb-0">Guru menjadi lebih mudah dalam pengelolaan kuis & ujian hingga proses penilaian. Tersedia bank soal yang membantu guru untuk mendapatkan konten soal.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/feedback.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Bimbingan</div>
                            <p class="text-md mb-0">Guru dan siswa dapat berkomunikasi secara pribadi di dalam tugas untuk membantu penyempurnaan hasil tugas serta evaluasi pengerjaan tugas yang sudah dikerjakan.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/solution.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Diskusi</div>
                            <p class="text-md mb-0">Guru dan siswa dapat berdiskusi dan berinteraksi mengenai materi pembelajaran di dalam kelas online.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/statistics.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Analitik Pembelajaran</div>
                            <p class="text-md mb-0">Guru dan siswa mendapatkan analisis hasil dari pembelajaran (kuis,ujian) yang telah dilakukan.</p>
                        </div>
                    </div>
                </div>
                <div class="section border-bottom">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/review.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Survei</div>
                            <p class="text-md mb-0">Pooling dapat memudahkan siswa dalam memberikan penilaian ke guru yang mengajar di kelas siswa tersebut.</p>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="d-flex align-items-center center-vertical-sm center-sm">
                        <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                            <img src="images/report.svg" width="80">
                        </div>
                        <div>
                            <div class="font-weight-bolder text-lg">Raport</div>
                            <p class="text-md mb-0">Memberikan kemudahan bagi guru dan siswa dalam melihat nilai hasil pembelajaran yang sudah dilaksanakan oleh siswa pada kelas.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Keuntungan Pengguna -->
<div id="keuntunganPengguna" class="section section-lg bg-lighter">
    <div class="container">
        <div class="row justify-content-center mb-5">
            <div class="col-md-10 text-center">
                <div class="title-lg font-weight-bolder mb-4"> Keuntungan Pengguna pada Kelas Online </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-3.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Sekolah</div>
                        <p class="mb-3">Sekolah dengan data informasi dari Scola LMS dapat dengan cepat dalam pengambilan keputusan, pantauan perkembangan dan aktifitas sekolah serta keaktifan siswa, guru, dan kelas.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-4.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Guru</div>
                        <p class="mb-3">Guru dapat mengelola dan membagikan materi pembelajaran (materi, kuis dan ujian) untuk siswa.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-1.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Siswa</div>
                        <p class="mb-3">Siswa dapat mengakses materi pembelajaran yang sudah dipersiapkan oleh guru.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center px-lg-4 mb-5">
                <div class="card shadow-sm h-100 mb-0">
                    <div class="card-body">
                        <img src="images/ilust-2.png" width="160" class="d-inline-block mb-4">
                        <div class="font-weight-bolder text-md mb-2">Orang Tua</div>
                        <p class="mb-3">Orang Tua dapat memantau perkembangan dan hasil belajar siswa secara real-time.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FAQ -->
<!-- Pagination -->
<div class="container px-lg-5">
    <div class="d-block pb-3 border-bottom pt-3">
        <div class="d-flex justify-content-end">
            <router-link to="ujian-online" class="pagination-link pagination-next">
                <div class="text-sm d-block mb-2">Selanjutnya<span class="fe fe-arrow-right ml-2"></span></div>
                <div class="d-block font-weight-bolder text-lg">UJIAN ONLINE</div>
            </router-link>
        </div>
    </div>
</div>

<!-- Testimonial -->
<!-- <div class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9 px-lg-5">
                <div class="d-flex align-items-center">
                    <div class="mr-5">
                        <img src="images/user-images-2.png" alt="User Testimonial" width="190">
                    </div>
                    <div>
                        <h2 class="m-0 font-weight-normal">"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat sit alias nostrum tempora magnam, possimus ipsam neque aliquam qui illumpsam repellendus amet accusantium sunt debitis mollitia at asperiores minus."</h2>
                        <div class="d-block mt-3">
                            <div class="font-weight-bolder">Catherine Wang</div>
                            <div class="text-sm">Kepala Sekolah SMA 1 Saturnus</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Resources -->
<!-- <div id="helpfulResources" class="section section-lg pb-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-left px-lg-5">
                <h1 class="title-lg font-weight-normal mb-5">Sumber bermanfaat</h1>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="card shadow-sm h-100 mb-0 lift">
                            <div class="card-img-top"></div>
                            <div class="card-body">
                                <a href="detail-kelas-scola.html" class="media-custom media-kelas media-text-detail media-card-list">
                                    <div class="media-content">
                                        <div class="media-content-head mb-3">
                                            <div class="d-flex align-items-center mb-2">
                                                <p class="m-0 text-sm text-muted">Tips & Trik</p>
                                            </div>
                                            <h2 class="name mb-0">Mengelola penilaian dari hasil siswa ujian</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card shadow-sm h-100 mb-0 lift">
                            <div class="card-img-top"></div>
                            <div class="card-body">
                                <a href="detail-kelas-scola.html" class="media-custom media-kelas media-text-detail media-card-list">
                                    <div class="media-content">
                                        <div class="media-content-head mb-3">
                                            <div class="d-flex align-items-center mb-2">
                                                <p class="m-0 text-sm text-muted">Tips & Trik</p>
                                            </div>
                                            <h2 class="name mb-0">Tahapan terperinci pengaturan butir soal</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card shadow-sm h-100 mb-0 lift">
                            <div class="card-img-top"></div>
                            <div class="card-body">
                                <a href="detail-kelas-scola.html" class="media-custom media-kelas media-text-detail media-card-list">
                                    <div class="media-content">
                                        <div class="media-content-head mb-3">
                                            <div class="d-flex align-items-center mb-2">
                                                <p class="m-0 text-sm text-muted">Blog</p>
                                            </div>
                                            <h2 class="name mb-0">Anulir soal? sekarang jadi lebih mudah</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <cta-components></cta-components>
    <help-cta-components></help-cta-components>
    <footer-components></footer-components>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import Cta from '@/components/Cta.vue'
  import lozad from 'lozad'

  export default {
    name: 'best-features',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'help-cta-components': HelpCta,
      'cta-components': Cta
    },
    metaInfo: {
      title: 'Fitur Andalan',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    mounted() {
        const observer = lozad();
        observer.observe();
        setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>