<template>
  <div class="blog-read">
    <header-components activeClass="blog-read"></header-components>


    <div class="section section-lg push-top border-bottom mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-11 px-lg-5">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="d-block pr-md-5">
                                <div class="d-block mb-4">
                                    <h1 class="title-lg mb-3">The 9 Best Animation Software for Beginners and Beyond</h1>
                                    <div class="d-block">
                                        <div class="d-block text-sm">22 Jun, 2020</div>
                                    </div>
                                </div>
                                <div class="d-block media-read mb-4">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                    <p>Tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                    <img src="images/pexels-photo-4260323.jpeg">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                    <br>
                                    <h2>Blog Read This</h2>
                                    <p>Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                    <img src="images/pexels-photo-4260483.jpeg">
                                    <p>Animi sapiente suscipit enim ad dolore odio, tenetur ex quos earum nisi optio et saepe reiciendis a soluta. Quod ex temporibus placeat.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="title-md mb-4">More Article</div>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <a href="" class="lists-link mb-4">
                                <div class="d-block font-600 lists-link-title mb-1">The 9 Best Animation Software for Beginners and Beyond</div>
                                <div class="d-block lists-link-subtitle">22 Jun, 2020</div>
                            </a>
                            <div class="d-block mt-4">
                                <a href="blog" class="text-sm">See all</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section section-sm mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-11 px-lg-5">
                    <div class="title-md font-600 d-block mb-4">Read Next</div>
                    <div class="row">
                        <div class="col-md-4">
                            <a href="blog-read" class="media-block mb-md-5 mb-4">
                                <div class="media-image mb-3" style="background-image:();"></div><!-- Masukin image di background-image -->
                                <div class="media-caption mb-2">
                                    <div class="d-block mb-2 title font-600">The 9 Best Animation Software for Beginners and Beyond</div>
                                    <div class="d-block text-sm mb-2">28 Jun, 2020</div>
                                    <p class="small">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat porro fugit alias, quae velit tenetur unde nulla inventore corrupti similique.</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="blog-read" class="media-block mb-md-5 mb-4">
                                <div class="media-image mb-3" style="background-image:();"></div><!-- Masukin image di background-image -->
                                <div class="media-caption mb-2">
                                    <div class="d-block mb-2 title font-600">The 9 Best Animation Software for Beginners and Beyond with Full Background Ahead</div>
                                    <div class="d-block text-sm mb-2">28 Jun, 2020</div>
                                    <p class="small">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat porro fugit alias, quae velit tenetur unde nulla inventore corrupti similique.</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="blog-read" class="media-block mb-md-5 mb-4">
                                <div class="media-image mb-3" style="background-image:();"></div><!-- Masukin image di background-image -->
                                <div class="media-caption mb-2">
                                    <div class="d-block mb-2 title font-600">The 9 Best Animation Software for Beginners and Beyond</div>
                                    <div class="d-block text-sm mb-2">28 Jun, 2020</div>
                                    <p class="small">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat porro fugit alias, quae velit tenetur unde nulla inventore corrupti similique.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <cta-components></cta-components>
    <footer-components></footer-components>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import Cta from '@/components/Cta.vue'

  export default {
    name: 'BlogRead',
    components: {
      'header-components': Header,
      'footer-components': Footer,
      'cta-components': Cta
    },
    metaInfo: {
      title: 'Blog',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    }
  }
</script>