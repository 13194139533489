<template>
    <div class="content">
        <header-components activeClass="content"></header-components>

        <!-- Materi Belajar -->
        <div class="section section-lg push-top">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="text-sm mb-2">Materi belajar dari tingkat SD hingga SMA</div>
                        <div class="title-lg font-weight-bolder mb-4">Kami menyediakan ratusan konten pembelajaran untuk
                            siswa dan guru</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 px-lg-4 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1.%20Dasar-Dasar%20Vektor-1.m4v" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-1.jpg');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Video Materi
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1.%20Ulasan-1.m4v" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Indonesia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1.%20Dasar-Dasar%20Vektor-1.m4v" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Matematika</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/60898499f0f5b57c6c374e4069686ca701855d084c523498df732389d0e8c2fe20190118163231.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Inggris</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 px-lg-4 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_c3fdbe65252ca7fa1797f87b2c220df5fa3803c74671448d3e8926634efa30ba20200722043219.mp4" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-2.jpg');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Video Animasi
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_11ebf424e71c47d8b52b5f602ca86b6148f0ce9aef714974ad99a2c75f53280520200805042508.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Matematika</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_0e16431d515bd0da1c2b6b2a467ed98d7c5d259f0ffe54fcc8093321e39b847220200706031802.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Indonesia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_c3fdbe65252ca7fa1797f87b2c220df5fa3803c74671448d3e8926634efa30ba20200722043219.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">IPA</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 px-lg-4 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_bcd50c5c1dd0b3cd5ff0d60114c8d9fcec382c942641ac481f8e1d537278832520200811021337.pdf" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-3.jpg');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Materi PDF
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_dddb96142e6426733b1354b25b220b7df8fa51f09d1daa086fd877a87b5d367720200716030053.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Indonesia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/fcd063126c23deed8f3616e9e47c14d66b204422dee1f8d091839c1cc0a2607420191021031052.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Inggris</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_bcd50c5c1dd0b3cd5ff0d60114c8d9fcec382c942641ac481f8e1d537278832520200811021337.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Matematika</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 px-lg-4 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/18012019102059HidrolisisAsamLemahBasaLemah_Easy_No1.mp4" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-4.jpg');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Video Pembahasan
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/14012019132831kamera%20-%203.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Fisika</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/18012019102059HidrolisisAsamLemahBasaLemah_Easy_No1.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Kimia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/17012019101608KLASIFIKASI%2001.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Biologi</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <img src="images/rocket-2.png" alt="rocket image" class="rocket-2 d-none">
        </div>
        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Cta from '@/components/Cta.vue'
    import lozad from 'lozad'

    export default {
        name: 'content',
        components: {
            'header-components': Header,
            'footer-components': Footer,
            'help-cta-components': HelpCta,
            'cta-components': Cta
        },
        metaInfo: {
            title: 'Konten',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
        }
    }
</script>